import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const loadingTrue = createAsyncThunk("loading/true", async () => {
  return true;
});
export const loadingFalse = createAsyncThunk("loading/false", async () => {
  return true;
});

const loadingSlice = createSlice({
  name: "loading",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadingTrue.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(loadingFalse.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export default loadingSlice.reducer;
