import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../Redux/Slices/authSlics";
import Styles from "../../Styles/Login.module.css";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import { setCookieData } from "../../BrowserStorage/cookieStorage";
import { loadingFalse, loadingTrue } from "../../Redux/Slices/loadingSlice";
import { ReactComponent as LoginRight } from "../CommonComponents/svg/LoginRight.svg";
import { ReactComponent as GnaConnect } from "../CommonComponents/svg/GnaConnect.svg";
import { ReactComponent as View } from "../CommonComponents/svg/View.svg";
import { ReactComponent as Lock } from "../CommonComponents/svg/Lock.svg";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";

const StyledTextField = styled((props) => <TextField {...props} />)(
  ({ theme }) => ({
    "& label,div,input": {
      fontFamily: "var(--paragraph-font-family)",
    },
  })
);

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state?.persistedReducer?.token);

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorSection, setErrorSection] = useState(false);

  const [rememberMe, setRememberMe] = useState(true);

  useEffect(() => {
    if (token) {
      return navigate("/dashboard");
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    let uName = userName?.trim();
    let uPass = password?.trim();
    dispatch(loadingTrue());

    if (uName?.length > 0 && uPass?.length >= 8) {
      let payload = {
        userName: uName,
        password: uPass,
        rememberMe: rememberMe,
      };

      dispatch(login({ payload: payload }))
        .unwrap()
        .then((res) => {
          setCookieData("auth", res?.user?.data?.data?.token);
          dispatch(loadingFalse());

          if (res?.user?.data?.data?.activeRole === "TRADER") {
            navigate("/dashboard");
          } else {
            navigate("/verification");
          }
        })
        .catch(() => {
          console.debug("error-login");
        });
    } else {
      setErrorSection("Please Enter UserName/Password");
    }
  };

  return (
    <div className={Styles.loginMainContainer}>
      <div className={Styles.loginAlterSection}>
        <div className={Styles.connectSectionMain}>
          <div style={{ paddingBottom: "20px" }}>
            <GnaConnect style={{ height: "68px", width: "117px" }} />
          </div>
          <div className={Styles.heardertext01}>
            <div className={Styles.boldHead}>Digital Power </div>
            <div className={Styles.boldHead}>Trading Platform</div>
            <div className={Styles.secondaryText}>
              Sign in to continue to your account{" "}
            </div>
          </div>
          <form onSubmit={handleLogin}>
            <div className={Styles.inputSection}>
              <div className={Styles.inputSectionMain}>
                <div className={Styles.sectionHeading}>Username</div>
                <div className={Styles.userNameInputSec}>
                  <div className={Styles.iconContainer}>
                    <PermIdentityOutlinedIcon />
                  </div>
                  <input
                    name="username"
                    type="text"
                    required
                    className={Styles.userInputSection}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
              </div>
              <div className={Styles.inputSectionMain}>
                <div className={Styles.sectionHeading}>Password</div>
                <div className={Styles.userNameInputSec}>
                  <div className={Styles.iconContainer}>
                    <Lock style={{ height: "16px", width: "16px" }} />
                  </div>
                  <input
                    type="password"
                    name="password"
                    minlength="8"
                    required
                    className={Styles.passwordInputSection}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className={Styles.viewSection}>
                    <View style={{ height: "16px", width: "16px" }} />
                  </div>
                </div>
              </div>
              <input type="submit" value={"Sign in"} className={Styles.signInButton}  />

              <div className={Styles.forgotPass}>Forgot Password?</div>
            </div>
          </form>
        </div>
        <div className={Styles.gnaFoterText}>
          © 2024, GNA Connect Private Limited | All Rights Reserved`
        </div>
      </div>
      <div className={Styles.loginImageSection}>
        <LoginRight style={{ height: "100%", width: "100%" }} />
      </div>
    </div>
  );
};

export default Login;
