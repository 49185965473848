import React, { useState } from "react";
import Styles from "../../Styles/Dashboard/LeftNavbar.module.css";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as GnaConnect } from "../CommonComponents/svg/GnaConnect.svg";
import { ReactComponent as HomeOrange } from "../CommonComponents/svg/HomeOrange.svg";
import { ReactComponent as HomeDark } from "../CommonComponents/svg/HomeDark.svg";
import { useDispatch, useSelector } from "react-redux";
import { getTraderDataOne } from "../../Redux/Slices/traderSlice";
import { traderType } from "../../Redux/Slices/otherSlice";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  width: "100%",
  borderRadius: "8px",
  background: "#FFFEFD",
  "&:not(:last-child)": {},
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
  />
))(({ theme }) => ({
  background: "#FFFEFD",
  borderRadius: "8px",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
  background: "#FFFEFD",
  borderRadius: "0px 0px 8px 8px",
}));

const Leftnavbar = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.persistedReducer?.token);
  const type = useSelector((state) => state?.otherSlice?.traderType);
  const role = useSelector(
    (state) => state?.persistedReducer?.user?.activeRole
  );

  const [expanded, setExpanded] = useState(
    role === "TRADER" ? "dashboard" : "request"
  );

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangeType = (type) => {
    dispatch(traderType(type));
    dispatch(getTraderDataOne({ token: token, type: type }));
  };

  return (
    <div className={Styles.leftSectionContainer}>
      <div>
        <GnaConnect style={{ height: "68px", width: "117px" }} />
      </div>
      <div className={Styles.contentSection}>
        {role === "TRADER" ? (
          <>
            <Accordion
              expanded={expanded === "dashboard"}
              onChange={handleChange("dashboard")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <div
                  className={
                    expanded === "dashboard"
                      ? Styles.accodianMain1
                      : Styles.accodianMain
                  }
                >
                  {expanded === "dashboard" ? (
                    <HomeOrange style={{ height: "18px", width: "18pz" }} />
                  ) : (
                    <HomeDark style={{ height: "18px", width: "18pz" }} />
                  )}
                  <span>My Dashboard</span>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={Styles.sectionDetails}>
                  <div>All</div>
                  <div
                    className={type === "consumer" && Styles.activeType}
                    onClick={() => handleChangeType("consumer")}
                  >
                    Consumer
                  </div>
                  <div
                    className={type === "generator" && Styles.activeType}
                    onClick={() => handleChangeType("generator")}
                  >
                    Generator
                  </div>
                  <div>Combined</div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "transaction"}
              onChange={handleChange("transaction")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <div
                  className={
                    expanded === "transaction"
                      ? Styles.accodianMain1
                      : Styles.accodianMain
                  }
                >
                  {expanded === "transaction" ? (
                    <HomeOrange style={{ height: "18px", width: "18pz" }} />
                  ) : (
                    <HomeDark style={{ height: "18px", width: "18pz" }} />
                  )}
                  <span>Transaction</span>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={Styles.sectionDetails}>
                  <div>Transaction</div>
                  <div>Bidding</div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "request"}
              onChange={handleChange("request")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <div
                  className={
                    expanded === "request"
                      ? Styles.accodianMain1
                      : Styles.accodianMain
                  }
                >
                  {expanded === "request" ? (
                    <HomeOrange style={{ height: "18px", width: "18pz" }} />
                  ) : (
                    <HomeDark style={{ height: "18px", width: "18pz" }} />
                  )}
                  <span>Requests</span>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={Styles.sectionDetails}>
                  <div>Pending</div>
                  <div>Accepted</div>
                  <div>Rejected</div>
                </div>
              </AccordionDetails>
            </Accordion>
          </>
        ) : (
          <Accordion
            expanded={expanded === "request"}
            onChange={handleChange("request")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <div
                className={
                  expanded === "request"
                    ? Styles.accodianMain1
                    : Styles.accodianMain
                }
              >
                {expanded === "request" ? (
                  <HomeOrange style={{ height: "18px", width: "18pz" }} />
                ) : (
                  <HomeDark style={{ height: "18px", width: "18pz" }} />
                )}
                <span>Requests</span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={Styles.sectionDetails}>
                <div>Pending</div>
                <div>Accepted</div>
                <div>Rejected</div>
              </div>
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    </div>
  );
};

export default Leftnavbar;
