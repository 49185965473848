import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../Redux/Slices/authSlics";
import { removeCookieData } from "../BrowserStorage/cookieStorage";
import Styles from "../Styles/Navbar.module.css";
import { ReactComponent as Notification } from "./CommonComponents/svg/Notification.svg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";

const Navbar = ({ value }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state?.persistedReducer?.user);
  const [typeDropDown, setTypeDropDown] = useState(false);
  const [typeValue, setTypeValue] = useState("Transactions");
  const [accountDropDown, setAccountDropDown] = useState(false);

  const handleLogout = () => {
    dispatch(logout())
      .unwrap()
      .then((res) => {
        removeCookieData("auth");
        navigate("/");
      })
      .catch(() => {
        console.debug("error-login");
      });
  };

  const handleType = (value) => {
    setTypeValue(value);
    setTypeDropDown(false);
  };

  return (
    <div className={Styles.navbarContainer}>
      <div className={Styles.typeText}>{value}</div>
      <div className={Styles.rightNavContent}>
        <div className={Styles.searchSection}>
          <div className={Styles.searchIcon}>
            <SearchIcon style={{ height: "24px", width: "24px" }} />
          </div>
          <input
            type="text"
            className={Styles.inputSection}
            placeholder="Enter your search key word"
          />
        </div>
        <Notification styles={{ height: "20px", width: "20px" }} />
        <div>
          <div
            onClick={() => setAccountDropDown(!accountDropDown)}
            className={Styles.accountSection}
          >
            <AccountCircleIcon style={{ height: "36px", width: "36px" }} />
            <div>{data?.firstName || data?.companyName || "Trader"}</div>
            <KeyboardArrowDownIcon style={{ height: "24px", width: "24px" }} />
          </div>
          {accountDropDown && (
            <div className={Styles.dropDownContent}>
              <div onClick={handleLogout}>Logout</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
