import { getApi } from "./apiPromise";

export const getUpdateTerms = ({ transId, token, type }) => {
  let url = `${process.env.REACT_APP_API_URL}/bidding/${type}/updateTerms?transactionId=${transId}`;
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return getApi(url, headers);
};

export const submitConsentForm = ({ token, transId, docId, type }) => {
  let url = `${process.env.REACT_APP_API_URL}/bidding/${type}/updateConsentDoc?transactionId=${transId}&documentId=${docId}`;
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return getApi(url, headers);
};

export const agreementForm = ({ token, transId, docId, type }) => {
  let url = `${process.env.REACT_APP_API_URL}/bidding/${type}/updateConsentDoc?transactionId=${transId}&documentId=${docId}`;
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return getApi(url, headers);
};

export const consentAcceptance = ({ token, transId, type }) => {
  let url = `${process.env.REACT_APP_API_URL}/bidding/${type}/consentAcceptance?transactionId=${transId}`;
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return getApi(url, headers);
};

export const agreementAcceptance = ({ token, transId, type }) => {
  let url = `${process.env.REACT_APP_API_URL}/bidding/${type}/agreementAcceptance?transactionId=${transId}`;
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return getApi(url, headers);
};

export const rejectSystem = ({ token, transId, type }) => {
  let url = `${process.env.REACT_APP_API_URL}/bidding/${type}/rejectConsent?transactionId=${transId}`;
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return getApi(url, headers);
};
