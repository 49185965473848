import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getConGenConnData,
  getConGenDashboardData,
} from "../../ApiOperation/dashboardData";

export const getUser = createAsyncThunk(
  "user/getConGen",
  async ({ token, type }) => {
    try {
      let data = await getConGenDashboardData({ token: token, type: type });
      return { user: data };
    } catch (err) {
      console.debug("error");
    }
  }
);

export const getUserConn = createAsyncThunk(
  "user/getConGenConnection",
  async ({ token, type, capId }) => {
    try {
      let data = await getConGenConnData({
        token: token,
        type: type,
        capId: capId,
      });
      return { user: data };
    } catch (err) {
      console.debug("error");
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        console.log("pending");
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.displayData = action.payload?.user?.data?.data;
      })
      .addCase(getUser.rejected, (state, action) => {
        console.log("error", action);
      });
    builder
      .addCase(getUserConn.pending, (state) => {
        console.log("pending");
      })
      .addCase(getUserConn.fulfilled, (state, action) => {
        state.connectedList = action.payload?.user?.data?.data;
      })
      .addCase(getUserConn.rejected, (state, action) => {
        console.log("error", action);
      });
  },
});

export default userSlice.reducer;
