import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import Styles from "../../Styles/Dashboard/VerificationDashboard.module.css";
import Styles1 from "../../Styles/Dashboard/DashboardMain.module.css";

import VerificationBox from "./VerificationBox";
import { getUser } from "../../Redux/Slices/userSlice";
import Leftnavbar from "./Leftnavbar";

const VerificationDashboard = ({ type = "generator" }) => {
  const token = useSelector((state) => state?.persistedReducer?.token);
  const role = useSelector(
    (state) => state?.persistedReducer?.user?.activeRole
  );
  const layerOne = useSelector((state) => state?.userSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);

  const handleClose = () => {
    setModal(false);
  };

  useEffect(() => {
    if (!token) {
      return navigate("/login");
    }
  }, []);

  useEffect(() => {
    dispatch(getUser({ token: token, type: type }))
      .unwrap()
      .then((res) => {
        if (res?.user?.data?.code === 200) {
        }
      })
      .catch(() => {});
  }, []);

  return (
    <>
      <div className={Styles1.dashboardMainContainer}>
        <div className={Styles1.dashboardLeftSection}>
          <Leftnavbar />
        </div>
        <div className={Styles1.dashboardRightContent}>
          <div className={Styles1.navContainer}>
            <Navbar value={"Requests"} />
          </div>
          {layerOne?.displayData?.map((ele) => (
            <VerificationBox data={ele} type={type} />
          ))}
        </div>
      </div>
    </>
  );
};

export default VerificationDashboard;
