import Cookies from "js-cookie";

export const setCookieData = (key, value) => {
  Cookies.set(key, JSON.stringify(value));
};

export const getCookieData = (key) => {
  return Cookies.get(key);
};

export const removeCookieData = (key) => {
  Cookies.remove(key);
};
