import React, { Fragment, useEffect, useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import SendIcon from "@mui/icons-material/Send";
import io from "socket.io-client";
import {
  Box,
  Container,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
} from "@mui/material";
// import chatService from "../Services/chat
import Styles from "../../Styles/Modal/ChatModal.module.css";

const ChatModal = ({ chatModal, closeChatModal, senderId, receiverId }) => {
  const fromUserData0 = useSelector((state) => state?.traderSlice?.displayData);
  const toUserData0 = useSelector((state) => state?.traderSlice?.connectedList);

  const fromUserData = fromUserData0?.filter(
    (ele) => ele?.id === senderId
  )?.[0];

  const toUserData = toUserData0?.filter((ele) => ele?.id === receiverId)?.[0];

  const [current_user, setUser] = useState(fromUserData?.name);
  const socket = useRef(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatMessage, setChatMessage] = useState("");
  const role = useSelector((state) => state?.sessionState?.role);
  const currentRoute = window.location.pathname;

  const fromUser = fromUserData?.name;
  const toUser = toUserData?.name;

  const messagesRef = useRef(null);

  const scrollToBottom = () => {
    messagesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  useEffect(() => {
    // Listen for incoming messages
    // setUser("test_user" + Date.parse(new Date()));
    try {
      socket.current = io("https://chat.gna.energy"); // Replace with your server URL
    } catch (err) {
      console.log("socketError: ", err);
    }
    try {
      socket.current.on("connect", () => {
        socket.current.emit("userDetails", { fromUser, toUser });
        console.log("Connected Successfully");
      });
    } catch (err) {
      console.log("ConnectionError: ", err);
    }

    socket.current.on("message", (message) => {
      setChatMessages((prevMessages) => [...prevMessages, message]);
    });

    socket.current.on("output", (message) => {
      setChatMessages(message);
    });

    //   socket.current.on('disconnect', (e) => {
    //           console.log("UserWent offline...",e);
    // });
    return () => {
      try {
        socket.current.disconnect(() => {});
        console.log("Disconnected Successfully");
      } catch (err) {
        console.log("DisconnectedError : ", err);
      }
    };
  }, []);

  const sendMessage = () => {
    // socket.current.emit('chat message', {user: current_user, message: chatMessage});
    // setChatMessage('');
    if (chatMessage.trim()) {
      let final = {
        fromUser: fromUser,
        toUser: toUser,
        msg: chatMessage,
      };
      let reqBody = {
        senderCapacityName: fromUser,
        receiverCapacityName: toUser,
        receivedMessage: chatMessage,
        specificationType: role,
      };
      socket.current.emit("chatMessage", final);
      // chatService
      //   .sendChatNotification(reqBody)
      //   .then((response) => {
      //     if (response?.data?.code === 200) {
      //     }
      //   })
      // .catch((err) => console.log("Api Error :", err));
      setChatMessage("");
    }
  };

  const listChatMessages = chatMessages.map((chatMessageDto, index) => (
    <div className={Styles.messageSection}>
      <ListItem
        key={index}
        className={`${Styles.message_wrapper} ${
          current_user === chatMessageDto.from
            ? Styles.self_message
            : Styles.others_message
        }`}
      >
        <ListItemText>
          <Paper
            className={`user-msg ${
              current_user === chatMessageDto.from ? "self" : "others"
            }`}
          >
            <Grid xs={10} container ref={messagesRef}>
              <Grid xs={12} className="user-name" item>
                {chatMessageDto.from}:
              </Grid>
              <Grid xs={12} item>
                {chatMessageDto.message}
              </Grid>
            </Grid>
          </Paper>
        </ListItemText>
      </ListItem>
    </div>
  ));

  return (
    <Modal
      open={chatModal}
      onClose={closeChatModal}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <div className={Styles.chatModalContainer}>
        <div className={Styles.topContain}>
          <div>{toUserData?.name}</div>
          <div onClick={closeChatModal} style={{ cursor: "pointer" }}>
            <CloseIcon />
          </div>
        </div>
        <div className={Styles.chatSection}>{listChatMessages}</div>
        <div className={Styles.bottonSearchContainer}>
          <div className={Styles.searchSec}>
            <TextField
              value={chatMessage}
              label="Type your message..."
              variant="outlined"
              onChange={(e) => {
                setChatMessage(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  sendMessage();
                }
              }}
              style={{ width: "100%" }}
              color="warning"
              multiline
              maxRows={4}
            />
          </div>
          <div className={Styles.sendbtn} onClick={() => sendMessage()}>
            <SendIcon
              style={{ height: "30px", width: "100%", color: "#f1935c" }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChatModal;
