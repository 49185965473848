import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loginUser } from "../../ApiOperation/user";

export const login = createAsyncThunk("auth/login", async ({ payload }) => {
  try {
    let data = await loginUser({ payload: payload });
    return { user: data };
  } catch (err) {}
});

export const logout = createAsyncThunk("auth/logout", async () => {
  try {
    return null;
    // eslint-disable-next-line no-unreachable
  } catch (err) {}
});

export const registerUser = createAsyncThunk("auth/register", async () => {
  try {
  } catch {}
});

const authSlice = createSlice({
  name: "auth",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {})
      .addCase(registerUser.fulfilled, (state, action) => {
        state.token = action.payload?.user?.data?.data;
        state.user = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {});
    builder
      .addCase(login.pending, (state) => {})
      .addCase(login.fulfilled, (state, action) => {
        state.token = action.payload?.user?.data?.data?.token;
        state.user = action.payload?.user?.data?.data;
        state.role = action.payload?.user?.data?.data?.activeRole;
      })
      .addCase(login.rejected, (state, action) => {});
    builder
      .addCase(logout.pending, (state) => {})
      .addCase(logout.fulfilled, (state, action) => {
        state.token = null;
        state.user = null;
        state.role = null;
      })
      .addCase(logout.rejected, (state, action) => {});
  },
});

// export const userDetails = (state) => state.auth.user;

export default authSlice.reducer;
