import React, { useState } from "react";
import Styles from "../../Styles/Dashboard/DashboardListing.module.css";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import PopupModal from "../Modal/PopupModal";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  border: "1px solid #ff7d00",
  borderRadius: "8px",
  "&:not(:last-child)": {
    // borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#fbfbfb",
  borderRadius: "8px",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  background: "#fbfbfb",
  borderRadius: "0px 0px 8px 8px",
}));

let arr = [1, 2, 3, 4];

const DashboardListing = ({ singleTrader }) => {
  const [expanded, setExpanded] = useState(arr[0]);
  const [modal, setModal] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleAgreementLevelOne = () => {
    setModal(true);
  };

  return (
    <>
      <div className={Styles.traderListingMainContainer}>
        {/* {singleTrader ? ( */}
        {singleTrader ? (
          <>
            {arr?.map((ele) => (
              <Accordion
                expanded={expanded === ele}
                onChange={handleChange(ele)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <div className={Styles.listingDetailsMain}>
                    <div className={Styles.listingNameText}>
                      C15_FEB2724_MAR0224
                    </div>
                    <div className={Styles.detailsSectionMain}>
                      <div className={Styles.detailsTexts}>
                        <div>Date</div>
                        <div>: 02-Mar-2024</div>
                      </div>
                      <div className={Styles.detailsTexts}>
                        <div>Time Slot</div>
                        <div>: 00.00 - 24.00</div>
                      </div>
                      <div className={Styles.detailsTexts}>
                        <div>Quantity(MW)</div>
                        <div>: 1MW</div>
                      </div>
                      <div className={Styles.detailsTexts}>
                        <div>Energy Rate</div>
                        <div>: ₹7</div>
                      </div>
                      <div className={Styles.detailsTexts}>
                        <div>Closed On</div>
                        <div>: 26-Feb-2024 07:00</div>
                      </div>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {arr?.map((ele) => (
                    <div className={Styles.eachSingleSelectionContainer}>
                      <div className={Styles.singleDetailsMainCont}>
                        <div className={Styles.consumerSection}>
                          <div className={Styles.detailsHeader}>
                            Consumer Detiails
                          </div>
                          <table className={Styles.tableContainer}>
                            <tr className={Styles.tableRow}>
                              <td className={Styles.tableCOntents}>Date</td>
                              <td className={Styles.tableCOntents}>
                                02-Mar-2024
                              </td>
                            </tr>
                            <tr className={Styles.tableRow}>
                              <td className={Styles.tableCOntents}>Date</td>
                              <td className={Styles.tableCOntents}>
                                02-Mar-2024
                              </td>
                            </tr>
                            <tr className={Styles.tableRow}>
                              <td className={Styles.tableCOntents}>Date</td>
                              <td className={Styles.tableCOntents}>
                                02-Mar-2024
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div className={Styles.generatorSection}>
                          <div className={Styles.detailsHeader}>
                            Generator Details
                          </div>
                          <table className={Styles.tableContainer}>
                            <tr>
                              <td className={Styles.tableCOntents}>Date</td>
                              <td className={Styles.tableCOntents}>
                                02-Mar-2024
                              </td>
                            </tr>
                            <tr>
                              <td className={Styles.tableCOntents}>Date</td>
                              <td className={Styles.tableCOntents}>
                                02-Mar-2024
                              </td>
                            </tr>
                            <tr>
                              <td className={Styles.tableCOntents}>Date</td>
                              <td className={Styles.tableCOntents}>
                                02-Mar-2024
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div
                        className={Styles.agreementBtn}
                        onClick={() => handleAgreementLevelOne()}
                      >
                        Proceed to Agreement
                      </div>
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        ) : (
          <>
            {arr?.map((ele) => (
              <Accordion
                expanded={expanded === ele}
                onChange={handleChange(ele)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <div className={Styles.listingDetailsMain}>
                    <div className={Styles.listingNameText}>
                      C15_FEB2724_MAR0224
                    </div>
                    <div className={Styles.detailsSectionMain}>
                      <div className={Styles.detailsTexts}>
                        <div>Date</div>
                        <div>: 02-Mar-2024</div>
                      </div>
                      <div className={Styles.detailsTexts}>
                        <div>Time Slot</div>
                        <div>: 00.00 - 24.00</div>
                      </div>
                      <div className={Styles.detailsTexts}>
                        <div>Quantity(MW)</div>
                        <div>: 1MW</div>
                      </div>
                      <div className={Styles.detailsTexts}>
                        <div>Energy Rate</div>
                        <div>: ₹7</div>
                      </div>
                      <div className={Styles.detailsTexts}>
                        <div>Closed On</div>
                        <div>: 26-Feb-2024 07:00</div>
                      </div>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={Styles.detailsMainCont}>
                    {arr?.map((ele) => (
                      <div className={Styles.listingMain}>
                        <div>Trader details Eg. Name</div>
                        <div className={Styles.containerDetailsSection}>
                          <table className={Styles.eachDetailsContainer}>
                            <tr>
                              <td>Region</td>
                              <td>:</td>
                              <td>XYZ</td>
                            </tr>
                            <tr>
                              <td>State</td>
                              <td>:</td>
                              <td>XYZ</td>
                            </tr>
                            <tr>
                              <td>Credit Rating</td>
                              <td>:</td>
                              <td>XYZ</td>
                            </tr>
                            <tr>
                              <td>State</td>
                              <td>:</td>
                              <td>AA+</td>
                            </tr>
                          </table>
                          <table className={Styles.eachDetailsContainer}>
                            <tr>
                              <td>Quantity (MW)</td>
                              <td>:</td>
                              <td>22.00</td>
                            </tr>
                            <tr>
                              <td>Energy Rate (₹ per Unit)</td>
                              <td>:</td>
                              <td>5.00</td>
                            </tr>
                            <tr>
                              <td>Capacity Start & End Date</td>
                              <td>:</td>
                              <td>01-Mar-2024/29-Mar-2024</td>
                            </tr>
                            <tr>
                              <td>Capacity Start & End Time</td>
                              <td>:</td>
                              <td>00:00/24:00</td>
                            </tr>
                          </table>
                        </div>
                        <div className={Styles.alterArea}>
                          {/* <div className={Styles.expTimerSection}>
                          <div>Expairy in : </div>
                          <div>Time Meter</div>
                        </div> */}
                          <div
                            className={Styles.agreementBtn}
                            onClick={() => handleAgreementLevelOne()}
                          >
                            Proceed to Agreement
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}
      </div>
      {modal && (
        <PopupModal type={"agreement"} modal={modal} setModal={setModal} />
      )}
    </>
  );
};

export default DashboardListing;
