import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getBidDetails,
  getCapacityBidDetails,
  getTraderConnectedData,
  getTraderData,
} from "../../ApiOperation/dashboardData";

export const getTraderDataOne = createAsyncThunk(
  "capacity/getAll",
  async ({ token, type }) => {
    try {
      let data = await getTraderData({ token: token, type: type });
      return { user: data };
    } catch (err) {
      console.debug("error");
    }
  }
);

export const getTraderConnectedList = createAsyncThunk(
  "capacity/listing",
  async ({ token, type, payload }) => {
    try {
      let data = await getTraderConnectedData({
        token: token,
        type: type,
        payload: payload,
      });
      return { user: data };
    } catch (err) {
      console.debug("error");
    }
  }
);
export const bidDetails = createAsyncThunk(
  "get/bidDetails",
  async ({ token, type, capId }) => {
    try {
      let data = await getBidDetails({
        token: token,
        type: type,
        capId: capId,
      });
      return { user: data };
    } catch (err) {
      console.debug("error");
    }
  }
);

export const capacityBidDetails = createAsyncThunk(
  "capacity/bids",
  async ({ token, type, capId, bidId }) => {
    try {
      let data = await getCapacityBidDetails({
        token: token,
        type: type,
        capId: capId,
        bidId: bidId,
      });
      return { user: data };
    } catch (err) {
      console.debug("error");
    }
  }
);

const traderSlice = createSlice({
  name: "consumer",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTraderDataOne.pending, (state) => {
        console.log("pending");
      })
      .addCase(getTraderDataOne.fulfilled, (state, action) => {
        state.displayData = action.payload?.user?.data?.data;
      })
      .addCase(getTraderDataOne.rejected, (state, action) => {
        console.log("error", action);
      });
    builder
      .addCase(getTraderConnectedList.pending, (state) => {
        console.log("pending");
      })
      .addCase(getTraderConnectedList.fulfilled, (state, action) => {
        state.connectedList = action.payload?.user?.data?.data;
      })
      .addCase(getTraderConnectedList.rejected, (state, action) => {
        console.log("error", action);
      });
    builder
      .addCase(bidDetails.pending, (state) => {
        console.log("pending");
      })
      .addCase(bidDetails.fulfilled, (state, action) => {
        state.bidDetail = action.payload?.user?.data?.data;
      })
      .addCase(bidDetails.rejected, (state, action) => {
        console.log("error", action);
      });
    builder
      .addCase(capacityBidDetails.pending, (state) => {
        console.log("pending");
      })
      .addCase(capacityBidDetails.fulfilled, (state, action) => {
        state.capacityBid = action.payload?.user?.data?.data;
      })
      .addCase(capacityBidDetails.rejected, (state, action) => {
        console.log("error", action);
      });
  },
});

export default traderSlice.reducer;
