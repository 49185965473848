import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import authDataSlice from "./Slices/authSlics";
import loadingSlice from "./Slices/loadingSlice";
import traderSlice from "./Slices/traderSlice";
import otherSlice from "./Slices/otherSlice";
import userSlice from "./Slices/userSlice";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, authDataSlice);

export const store = configureStore({
  reducer: {
    persistedReducer: persistedReducer,
    traderSlice: traderSlice,
    loadingSlice: loadingSlice,
    otherSlice: otherSlice,
    userSlice: userSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
