import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const traderType = createAsyncThunk("traderType", async (value) => {
  return value;
});

export const agreementData = createAsyncThunk(
  "agreementData",
  async (value) => {
    return value;
  }
);
export const eventStatus = createAsyncThunk("eventStatus", async (value) => {
  return value;
});

const otherSlice = createSlice({
  name: "other",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(traderType.fulfilled, (state, action) => {
      state.traderType = action?.payload;
    });
    builder.addCase(agreementData.fulfilled, (state, action) => {
      state.agreementData = action?.payload;
    });
    builder.addCase(eventStatus.fulfilled, (state, action) => {
      state.eventStatus = action?.payload;
    });
  },
});

export default otherSlice.reducer;
