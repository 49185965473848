import { postApi2 } from "./apiPromise";

export const loginUser = ({ payload }) => {
  let url = `${process.env.REACT_APP_API_URL}/auth/login`;
  let headers = {
    // Authorization: token ? token : "",
    "Content-Type": "application/json",
  };
  let body = payload;

  return postApi2(url, body, headers);
};
