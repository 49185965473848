import React, { useState } from "react";
import Styles from "../../Styles/Modal/EditModal.module.css";
import { useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

function convertDateFormat(dateString) {
  const parts = dateString.split("-");
  const months = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  // Extract day, month, and year
  const day = parts[0];
  const month = months[parts[1]];
  const year = parts[2];

  // Create a new date string in the desired format
  const newDateString = `${year}-${month}-${day}`;

  return newDateString;
}

const EditModal = ({ editId, editModal, setEditModal }) => {
  const data = useSelector((state) => state?.traderSlice?.connectedList);
  const editData = data?.filter((ele) => ele?.id === editId)?.[0];
  console.debug(editData);
  const [startDate, setStartDate] = useState(
    convertDateFormat(editData?.dateStartAt)
  );
  const [endDate, setEndDate] = useState(
    convertDateFormat(editData?.dateEndAt)
  );
  const [startTime, setStartTime] = useState(editData?.slotStartAt);
  const [endTime, setEndTime] = useState(editData?.slotEndAt);

  const [energyQty, setEnergyQty] = useState(
    editData?.sellEnergy || editData?.purchaseEnergy
  );

  const [energyCost, setEnergyCost] = useState(
    editData?.sellingCost || editData?.landingCost
  );
  const [tradingMargin, setReadingMargin] = useState(0);
  const handleClose = () => {
    setEditModal(false);
  };

  const handleEdit = () => {
    handleClose();
  };
  return (
    <Modal
      open={editModal}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <div className={Styles.editModalContainer}>
        <div className={Styles.editHead}>
          <div className={Styles.editName}>{editData?.name}</div>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <CloseIcon />
          </div>
        </div>
        <div className={Styles.editLayerContainer}>
          <div className={Styles.editLayerFirst}>
            <div className={Styles.eachElementLayerFirst}>
              <div className={Styles.elementHeading}>Region</div>
              <div className={Styles.nonEditData}>
                {editData?.company?.region}
              </div>
            </div>
            <div className={Styles.eachElementLayerFirst}>
              <div className={Styles.elementHeading}>State</div>
              <div className={Styles.nonEditData}>
                {editData?.company?.state}
              </div>
            </div>
            <div className={Styles.eachElementLayerFirst}>
              <div className={Styles.elementHeading}>Rating</div>
              <div className={Styles.nonEditData}>
                {editData?.company?.creditRating}
              </div>
            </div>
          </div>
          <div className={Styles.editLayerSecond}>
            <div className={Styles.layerSectionSecond}>
              <div className={Styles.eachElementL2}>
                <div className={Styles.elementHeading}>Start Date*</div>
                <input
                  type="date"
                  name=""
                  id=""
                  onChange={(e) => console.debug(e.target.value)}
                  className={Styles.inputAreasTimeDate}
                  value={startDate}
                />
              </div>
              <div className={Styles.eachElementL2}>
                <div className={Styles.elementHeading}>End Date*</div>
                <input
                  type="date"
                  name=""
                  id=""
                  className={Styles.inputAreasTimeDate}
                  value={endDate}
                />
              </div>
            </div>
            <div className={Styles.layerSectionSecond}>
              <div className={Styles.eachElementL2}>
                <div className={Styles.elementHeading}>Start Time*</div>
                <input
                  type="time"
                  name=""
                  id=""
                  onChange={(e) => console.debug(e.target.value)}
                  className={Styles.inputAreasTimeDate}
                  value={startTime}
                />
              </div>
              <div className={Styles.eachElementL2}>
                <div className={Styles.elementHeading}>End Time*</div>
                <input
                  type="time"
                  name=""
                  id=""
                  value={endTime}
                  className={Styles.inputAreasTimeDate}
                />
              </div>
            </div>
          </div>
          <div className={Styles.energyLevelThree}>
            <div className={Styles.eachEnergyElement}>
              <div className={Styles.elementHeading}>
                Energy Rate (₹ per unit)
              </div>
              <input
                type="text"
                value={energyQty}
                onChange={(e) => setEnergyQty(e.target.value)}
                className={Styles.textInput}
              />
            </div>
            <div className={Styles.eachEnergyElement}>
              <div className={Styles.elementHeading}>Quantity (IN MW)</div>
              <input
                type="text"
                value={energyCost}
                onChange={(e) => setEnergyCost(e.target.value)}
                className={Styles.textInput}
              />
            </div>
            <div className={Styles.eachEnergyElement}>
              <div className={Styles.elementHeading}>
                Trading Margin (₹ Per Unit)
              </div>
              <input
                type="text"
                value={tradingMargin}
                onChange={(e) => setReadingMargin(e.target.value)}
                className={Styles.textInput}
              />
            </div>
          </div>
          <div className={Styles.updateBtn} onClick={handleEdit}>
            Update
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditModal;
