import React, { useEffect, useState } from "react";
import Styles from "../../Styles/Modal/Modal.module.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  bidDetails,
  getTraderConnectedList,
  getTraderDataOne,
} from "../../Redux/Slices/traderSlice";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";
import {
  agreementAcceptance,
  agreementForm,
  getUpdateTerms,
  rejectSystem,
  submitConsentForm,
} from "../../ApiOperation/operation";
import { eventStatus } from "../../Redux/Slices/otherSlice";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { getConGenConnData } from "../../ApiOperation/dashboardData";

const PopupModal = ({
  modal,
  handleClose,
  upperId,
  listId,
  setEditModal,
  verificationModal,
  accType,
  newId,
  tBidId,
}) => {
  const dispatch = useDispatch();
  const upparData = useSelector((state) => state?.traderSlice);
  const layerOne = useSelector((state) => state?.userSlice);
  const buyerData = (upparData || layerOne)?.displayData?.filter(
    (ele) => ele?.id === upperId
  )?.[0];
  const buyerDataUser = layerOne?.displayData?.filter(
    (ele) => ele?.id === upperId
  )?.[0];
  const sellerData = upparData?.connectedList?.filter(
    (ele) => ele?.id === listId
  )?.[0];
  const [tData, setTData] = useState({});

  console.debug(tData);
  useEffect(() => {
    fetchListData();
  }, []);

  const fetchListData = async () => {
    let res = await getConGenConnData({
      token: token,
      type: accType,
      capId: upperId,
    });
    if (res?.data?.code === 200) {
      setTData(res?.data?.data?.page?.[0]);
    }
  };

  const transId = useSelector(
    (state) => state?.traderSlice?.capacityBid?.bidCapacity?.id
  );
  const type = useSelector((state) => state?.otherSlice?.traderType);
  const token = useSelector((state) => state?.persistedReducer?.token);
  const status = useSelector((state) => state?.otherSlice?.eventStatus);
  const [checkbox, setCheckbox] = useState(false);
  const [file, setFile] = useState(false);
  const [fileName, setFileName] = useState("");
  const [docId, setDocId] = useState("");
  const [tradingMargin, setReadingMargin] = useState(0);

  const handleProceed = () => {
    if (verificationModal) {
      return handleVerify();
    }
    if (status === "t1") {
      termSection();
      dispatch(eventStatus("t2"));
    } else if (status === "t2") {
      consentSection();
      dispatch(eventStatus("t3"));
    } else if (status === "t3") {
      agreementSection();
      dispatch(eventStatus("t4"));
    }
  };

  const handleVerify = async () => {
    const res = await agreementAcceptance({
      token: token,
      transId: newId?.[0]?.id,
      type: accType,
    });

    handleClose();
  };

  const handleCloseTrans = () => {
    handleClose();
    handleFetchData("PLACED", upperId);
  };

  const termSection = async () => {
    let response = await getUpdateTerms({
      transId: transId,
      token: token,
      type: type,
    });
    console.debug("termsAcceptance", response);
    handleCloseTrans();
  };

  const consentSection = async () => {
    let response = await submitConsentForm({
      token: token,
      transId: transId,
      docId: docId,
      type: type,
    });
    console.debug("consentDone", response);
    handleCloseTrans();
  };

  const agreementSection = async () => {
    let response = await agreementForm({
      token: token,
      transId: transId,
      docId: docId,
      type: type,
    });
    console.debug("agreement", response);
    handleCloseTrans();
  };

  const handleFetchData = (value, id) => {
    dispatch(
      bidDetails({
        token: token,
        type: type,
        capId: id,
      })
    );
    let userType;

    if (type === "generator") {
      userType = "consumer";
    }
    if (type === "consumer") {
      userType = "generator";
    }

    let data = {
      search: [
        {
          operation: "LIKE",
          key:
            type === "generator"
              ? "bids_capacityGenerator_id"
              : "bids_capacityConsumer_id",
          value: id,
          value1: null,
        },
        value === "PLACED" && {
          operation: "LIKE",
          key:
            type === "generator"
              ? "bids_generatorStatus"
              : "bids_consumerStatus",
          value: value,
          value1: null,
        },
        value === "ACCEPTED" && {
          operation: "LIKE",
          key:
            type === "generator"
              ? "ownBids_generatorStatus"
              : "ownBids_consumerStatus",
          value: value,
          value1: null,
        },
        value === "RECEIVED" && {
          operation: "LIKE",
          key:
            type === "generator"
              ? "ownBids_generatorStatus"
              : "ownBids_consumerStatus",
          value: value,
          value1: null,
        },
      ].filter(Boolean),
    };

    dispatch(
      getTraderConnectedList({
        token: token,
        type: userType,
        payload: data,
      })
    )
      .unwrap()
      .then((res) => {
        if (res?.user?.data?.code === 200) {
        }
      })
      .catch(() => {
        console.debug("error-login");
      });
  };

  const handlefileUpload = async (value, type) => {
    const selectedFile = value?.[0];
    setFileName(selectedFile?.name);
    const form = new FormData();
    form.append("file", selectedFile);
    form.append("type", type);
    form.append("capacityConsumerId", upperId);
    form.append("capacityGeneratorId", listId);

    try {
      const response = await axios.post(
        "${process.env.REACT_APP_API_URL}/document/generatorUpload",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.code === 200) {
        setDocId(response?.data?.data?.id);
        setFile(true);
      }
    } catch (error) {}
  };

  const handleReject = async () => {
    let bidID = tBidId || newId?.[0]?.id;

    let res = await rejectSystem({
      token: token,
      transId: bidID,
      type: accType || type,
    });
    console.debug(res);
    handleClose();
  };

  return (
    <Modal
      open={modal}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <div className={Styles.modalContainer}>
        <div className={Styles.agreementHead}>
          <div className={Styles.popHead}>
            {verificationModal && "Verify"}
            {status === "t1" && "Terms and Conditions"}
            {status === "t2" && "Consent"}
            {(status === "t3" || status === "t4") && "Agreement"}
            {status === "t5" && (
              <div className={Styles.agrView}>
                <span>Agreement</span>
                <span style={{ cursor: "pointer" }}>
                  <RemoveRedEyeIcon
                    style={{ height: "20px", width: "20px", color: "#f1935c" }}
                  />
                </span>
              </div>
            )}
          </div>
          <div onClick={handleClose}>
            <CloseIcon />
          </div>
        </div>
        <div className={Styles.bidDetails}>
          <div className={Styles.detailsMain}>
            <div className={Styles.detailsHeader}>
              <div>Details</div>
              <div>Buyer</div>
              <div>Seller</div>
            </div>
            <div className={Styles.detailsData}>
              <div>Name</div>
              <div>
                {buyerData?.company?.companyName ||
                  buyerDataUser?.company?.companyName}
              </div>
              <div>
                {sellerData?.company?.companyName ||
                  tData?.company?.companyName}
              </div>
            </div>
            <div className={Styles.detailsData}>
              <div>Region</div>
              <div>
                {buyerData?.company?.region || buyerDataUser?.company?.region}
              </div>
              <div>{sellerData?.company?.region || tData?.company?.region}</div>
            </div>
            <div className={Styles.detailsData}>
              <div>State</div>
              <div>
                {buyerData?.company?.state || buyerDataUser.company?.state}
              </div>
              <div>{sellerData?.company?.state || tData?.company?.state}</div>
            </div>
            <div className={Styles.detailsData}>
              <div>Credit Rating</div>
              <div>
                {buyerData?.company?.creditRating ||
                  buyerDataUser?.company?.creditRating}
              </div>
              <div>
                {sellerData?.company?.creditRating ||
                  tData?.company?.creditRating}
              </div>
            </div>
          </div>
          <div className={Styles.detailsMain}>
            <div className={Styles.detailsHeader}>
              <div>Terms And Condition</div>
              <div>Listing Values</div>
              <div className={Styles.editContainer}>
                {verificationModal ? (
                  <span>Final Values</span>
                ) : (
                  <>
                    <span>Edited Values</span>
                    <span onClick={() => setEditModal(true)}>
                      <BorderColorIcon
                        style={{
                          height: "20px",
                          width: "20px",
                          color: "#9F9F9F",
                        }}
                      />
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className={Styles.detailsData}>
              <div>Energy Rate (₹ Per Unit)</div>
              <div>
                {buyerData?.sellingCost ||
                  buyerData?.landingCost ||
                  buyerDataUser?.sellingCost ||
                  buyerDataUser?.landingCost}
              </div>
              <div>
                {sellerData?.landingCost ||
                  sellerData?.sellingCost ||
                  tData?.consumerLandedCost}
              </div>
            </div>
            <div className={Styles.detailsData}>
              <div>Quantity (MW)</div>
              <div>
                {buyerData?.sellEnergy ||
                  buyerData?.purchaseEnergy ||
                  buyerDataUser?.sellEnergy ||
                  buyerDataUser?.purchaseEnergy}
              </div>
              <div>
                {sellerData?.purchaseEnergy ||
                  sellerData?.sellEnergy ||
                  tData?.energyInMW}
              </div>
            </div>
            <div className={Styles.detailsData}>
              <div>Capacity Start and End Date</div>
              <div>
                {buyerData?.dateStartAt || buyerDataUser?.dateStartAt} -{" "}
                {buyerData?.dateEndAt || buyerDataUser?.dateEndAt}
              </div>
              <div>
                {sellerData?.dateStartAt || tData?.dateStartAt} -{" "}
                {sellerData?.dateEndAt || tData?.dateEndAt}
              </div>
            </div>
            <div className={Styles.detailsData}>
              <div>Capacity Start and End Time</div>
              <div>
                {buyerData?.slotStartAt || buyerDataUser?.slotStartAt} -{" "}
                {buyerData?.slotEndAt || buyerDataUser?.slotEndAt}
              </div>
              <div>
                {sellerData?.slotStartAt || tData?.slotStartAt} -{" "}
                {sellerData?.slotEndAt || tData?.slotEndAt}
              </div>
            </div>
            <div className={Styles.detailsData}>
              <div>Trading Margin (₹ Per Unit)</div>
              <div>-</div>
              <div>{tradingMargin}</div>
            </div>
          </div>
        </div>
        {verificationModal && (
          <div className={Styles.termsFooter}>
            <div
              onClick={() => setCheckbox(!checkbox)}
              className={Styles.checkboxSection}
            >
              {checkbox ? (
                <CheckBoxIcon style={{ color: "#f1935c" }} />
              ) : (
                <CheckBoxOutlineBlankIcon />
              )}
              <div>I verify all the contents stated above.</div>
            </div>
            <div className={Styles.btnSection}>
              <div className={Styles.rejectBtn} onClick={handleReject}>
                Reject
              </div>
              <div
                className={
                  checkbox ? Styles.viewAgreementTrue : Styles.viewAgreement
                }
                onClick={handleProceed}
              >
                Accept
              </div>
            </div>
          </div>
        )}

        {status === "t1" && (
          <div className={Styles.termsFooter}>
            <div
              onClick={() => setCheckbox(!checkbox)}
              className={Styles.checkboxSection}
            >
              {checkbox ? (
                <CheckBoxIcon style={{ color: "#f1935c" }} />
              ) : (
                <CheckBoxOutlineBlankIcon />
              )}
              <div>I agree to the Terms and Conditions</div>
            </div>
            <div className={Styles.btnSection}>
              <div className={Styles.rejectBtn} onClick={handleReject}>
                Reject
              </div>
              <div
                className={
                  checkbox ? Styles.viewAgreementTrue : Styles.viewAgreement
                }
                onClick={handleProceed}
              >
                Approve
              </div>
            </div>
          </div>
        )}
        {status === "t2" && (
          <div className={Styles.termsFooter}>
            <div
              onClick={() => setCheckbox(!checkbox)}
              className={Styles.checkboxSection}
            >
              <div>
                Please upload the <b>CONSENT</b> File
              </div>
            </div>
            <div className={Styles.btnSection}>
              <div className={Styles.inputFile}>
                <input
                  type="file"
                  onChange={(e) => handlefileUpload(e.target.files, "CONSENT")}
                  id="input-file"
                />
                <label className={Styles.inputLabel} htmlFor="input-file">
                  <AttachFileIcon style={{ height: "16px" }} />
                  <span>{fileName || "Upload The File"}</span>
                </label>
              </div>
              <div
                className={
                  file ? Styles.viewAgreementTrue : Styles.viewAgreement
                }
                onClick={handleProceed}
              >
                Send
              </div>
            </div>
          </div>
        )}
        {status === "t3" && (
          <div className={Styles.termsFooter}>
            <div
              onClick={() => setCheckbox(!checkbox)}
              className={Styles.checkboxSection}
            >
              <div>
                Please upload the <b>AGREEMENT</b> File
              </div>
            </div>
            <div className={Styles.btnSection}>
              <div className={Styles.inputFile}>
                <input
                  type="file"
                  onChange={(e) =>
                    handlefileUpload(e.target.files, "AGREEMENT")
                  }
                  id="input-file"
                />
                <label className={Styles.inputLabel} htmlFor="input-file">
                  <AttachFileIcon style={{ height: "16px" }} />
                  <span>{fileName || "Upload The File"}</span>
                </label>
              </div>
              <div
                className={
                  file ? Styles.viewAgreementTrue : Styles.viewAgreement
                }
                onClick={handleProceed}
              >
                Send
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PopupModal;
