import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardMultiTrader from "./DashboardMultiTrader";
import DashboardSingleTrader from "./DashboardSingleTrader";
import Navbar from "../Navbar";
import Styles from "../../Styles/Dashboard/DashboardMain.module.css";
import { getTraderDataOne } from "../../Redux/Slices/traderSlice";
import TradeList from "./TradeList";
import { traderType } from "../../Redux/Slices/otherSlice";
import Leftnavbar from "./Leftnavbar";

const DashboardMainpage = ({ type = "generator" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.persistedReducer?.token);
  const role = useSelector(
    (state) => state?.persistedReducer?.user?.activeRole
  );

  const traderListing = useSelector((state) => state?.traderSlice?.displayData);
  const [empty, setEmpty] = useState(false);

  useEffect(() => {

    if (role !== "TRADER") {
      navigate("/verification");
    }

    if (type) {
      dispatch(traderType(type));
    }

    dispatch(getTraderDataOne({ token: token, type: type }))
      .unwrap()
      .then((res) => {
        if (res?.user?.data?.code !== 200) {
        } else if (
          res?.user?.data?.code === 200 &&
          res?.user?.data?.data?.length === 0
        ) {
          return setEmpty(true);
        }
      })
      .catch(() => {
        console.debug("error-login");
      });
  }, []);

  return (
    <div className={Styles.dashboardMainContainer}>
      <div className={Styles.dashboardLeftSection}>
        <Leftnavbar />
      </div>
      <div className={Styles.dashboardRightContent}>
        <div className={Styles.navContainer}>
          <Navbar value={"My Dashboard"} />
        </div>
        <div className={Styles.dashboardDetailsSection}>
          {traderListing?.length > 0 && <TradeList data={traderListing} />}
        </div>
      </div>
    </div>
  );
};

export default DashboardMainpage;
