import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import MainPage from "../Components/MainPage";
import Login from "../Components/Auth/Login";
import DashboardMainpage from "../Components/Dashboard/DashboardMainpage";
import Error404 from "../Components/Error404";
import VerificationDashboard from "../Components/Dashboard/VerificationDashboard";

const AllRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path={"/"} element={<MainPage />} />
        <Route path={"/login"} element={<Login />} />
        <Route exact path={"/dashboard"} element={<DashboardMainpage />} />
        <Route
          exact
          path={"/verification"}
          element={<VerificationDashboard />}
        />
        <Route exact path={"*"} element={<Error404 />} />
      </Routes>
    </Router>
  );
};

export default AllRoutes;
