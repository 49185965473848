import React, { useEffect, useState } from "react";
import Styles from "../../Styles/Dashboard/VerificationDashboard.module.css";
import { getConGenConnData } from "../../ApiOperation/dashboardData";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PopupModal from "../Modal/PopupModal";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BoltIcon from "@mui/icons-material/Bolt";

import Leftnavbar from "./Leftnavbar";
import StatusBar from "../CommonComponents/StatusBar/StatusBar";
import { getUser, getUserConn } from "../../Redux/Slices/userSlice";
import { bidDetails } from "../../Redux/Slices/traderSlice";

const VerificationBox = ({ data, type }) => {
  const token = useSelector((state) => state?.persistedReducer?.token);
  const role = useSelector(
    (state) => state?.persistedReducer?.user?.activeRole
  );
  const layerOne = useSelector((state) => state?.userSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [bidId, setBidId] = useState();

  const handleClose = () => {
    setModal(false);
  };

  const [tData, setTData] = useState({});
  useEffect(() => {
    fetchListData();
  }, []);

  const fetchListData = async () => {
    let res = await getConGenConnData({
      token: token,
      type: type,
      capId: data?.id,
    });
    if (res?.data?.code === 200) {
      setTData(res?.data?.data?.page?.[0]);
    }
    dispatch(
      bidDetails({
        token: token,
        type: type,
        capId: data?.id,
      })
    )
      .unwrap()
      .then((res) => {
        if (res?.user?.data?.code === 200) {
          setBidId(res?.user?.data?.data);
        }
      })
      .catch(() => {});
  };

  return (
    <>
      {modal && (
        <PopupModal
          modal={modal}
          handleClose={handleClose}
          upperId={data?.id}
          listId={tData?.id}
          verificationModal={true}
          accType={type}
          newId={bidId}
        />
      )}
      <div className={Styles.listingContainer}>
        <div className={Styles.dataCOntainer}>
          <div className={Styles.eeachListCOntainer}>
            <div className={Styles.detailsHeadingText}>{data?.name}</div>
            <div className={Styles.listDetails}>
              <div className={Styles.detislLOne}>
                <div className={Styles.detHeading}>
                  <DateRangeIcon
                    style={{
                      height: "15px",
                      width: "15px",
                      color: "#f1935c",
                    }}
                  />
                  <span>Date</span>
                </div>
                <div className={Styles.detValue}>
                  {data?.dateStartAt} to {data?.dateEndAt}
                </div>
              </div>
              <div className={Styles.detislLOne}>
                <div className={Styles.detHeading}>
                  <AccessTimeIcon
                    style={{
                      height: "15px",
                      width: "15px",
                      color: "#f1935c",
                    }}
                  />
                  <span>Time Slot</span>
                </div>
                <div className={Styles.detValue}>
                  {data?.slotStartAt} to {data?.slotEndAt}
                </div>
              </div>
              <div className={Styles.detislLOne}>
                <div className={Styles.detHeading}>
                  <span>Quantity </span>
                </div>
                <div className={Styles.detValue}>
                  {data?.purchaseEnergy || data?.sellEnergy} MW
                </div>
              </div>
              <div className={Styles.detislLOne}>
                <div className={Styles.detHeading}>
                  <BoltIcon
                    style={{
                      height: "15px",
                      width: "15px",
                      color: "#f1935c",
                    }}
                  />
                  <span>Energy Rate</span>
                </div>
                <div className={Styles.detValue}>
                  ₹ {data?.landingCost || data?.sellingCost}/unit
                </div>
              </div>
            </div>
          </div>
          <div className={Styles.eeachListCOntainer}>
            <div className={Styles.detailsHeadingText}>
              {tData?.capacityName}
            </div>
            <div className={Styles.listDetails}>
              <div className={Styles.detislLOne}>
                <div className={Styles.detHeading}>
                  <DateRangeIcon
                    style={{
                      height: "15px",
                      width: "15px",
                      color: "#f1935c",
                    }}
                  />
                  <span>Date</span>
                </div>
                <div className={Styles.detValue}>
                  {tData?.dateStartAt} to {tData?.dateEndAt}
                </div>
              </div>
              <div className={Styles.detislLOne}>
                <div className={Styles.detHeading}>
                  <AccessTimeIcon
                    style={{
                      height: "15px",
                      width: "15px",
                      color: "#f1935c",
                    }}
                  />
                  <span>Time Slot</span>
                </div>
                <div className={Styles.detValue}>
                  {tData?.slotStartAt} to {tData?.slotEndAt}
                </div>
              </div>
              <div className={Styles.detislLOne}>
                <div className={Styles.detHeading}>
                  <span>Quantity </span>
                </div>
                <div className={Styles.detValue}>
                  {tData?.purchaseEnergy ||
                    tData?.sellEnergy ||
                    tData?.energyInMW}{" "}
                  MW
                </div>
              </div>
              <div className={Styles.detislLOne}>
                <div className={Styles.detHeading}>
                  <BoltIcon
                    style={{
                      height: "15px",
                      width: "15px",
                      color: "#f1935c",
                    }}
                  />
                  <span>Energy Rate</span>
                </div>
                <div className={Styles.detValue}>
                  ₹{" "}
                  {tData?.landingCost ||
                    tData?.sellingCost ||
                    tData?.consumerLandedCost}
                  /unit
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.alterSec}>
          <div className={Styles.statusBar}>
            <StatusBar />
          </div>
          <div className={Styles.buttonSec}>
            <button
              className={Styles.viewAgrBtn}
              onClick={() => setModal(true)}
            >
              View Agreement
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerificationBox;
