import "./Styles/StyleGuide.css";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import AllRoutes from "./Routes/AllRoutes";
import { useSelector } from "react-redux";
import Loader from "./Components/Loader";

function App() {
  const loading = useSelector((state) => state?.loadingSlice?.loading);
  return (
    <div>
      {loading && <Loader open={loading} />}
      <div>
        <AllRoutes />
      </div>
    </div>
  );
}

export default App;
