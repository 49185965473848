import React, { useEffect, useState } from "react";
import Styles from "../../Styles/Dashboard/TradeList.module.css";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BoltIcon from "@mui/icons-material/Bolt";
import PlaceIcon from "@mui/icons-material/Place";
import PopupModal from "../Modal/PopupModal";
import { useDispatch, useSelector } from "react-redux";
import { agreementData, eventStatus } from "../../Redux/Slices/otherSlice";
import { bidDetails, capacityBidDetails } from "../../Redux/Slices/traderSlice";
import { loadingFalse, loadingTrue } from "../../Redux/Slices/loadingSlice";
import StatusBar from "../CommonComponents/StatusBar/StatusBar";
import EditModal from "../Modal/EditModal";
import Chat from "../CommonComponents/chat/Chat";
import ChatModal from "../Modal/ChatModal";

const ListingCard = ({ data, bidId, handleId }) => {
  const dispatch = useDispatch();
  const type = useSelector((state) => state?.otherSlice?.traderType);
  const token = useSelector((state) => state?.persistedReducer?.token);
  let uid = bidId?.filter(
    (ele) => (ele?.capacityConsumerId || ele?.capacityGeneratorId) === data?.id
  )?.[0]?.id;
  const status = useSelector((state) => state?.otherSlice?.eventStatus);
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editId, setEditId] = useState("");
  const [chatModal, setChatModal] = useState(false);

  const handleProceed = () => {
    dispatch(loadingTrue());
    dispatch(
      capacityBidDetails({
        token: token,
        type: type,
        capId: handleId,
        bidId: uid,
      })
    );

    setTimeout(() => {
      dispatch(loadingFalse());
      setModal(true);
    }, 2000);
  };

  const handleEdit = () => {
    setEditModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };
  const closeChatModal = () => {
    setChatModal(false);
  };

  return (
    <>
      {modal && (
        <PopupModal
          modal={modal}
          handleClose={handleClose}
          upperId={handleId}
          listId={data?.id}
          setEditModal={setEditModal}
          tBidId = {uid}
        />
      )}
      {editModal && (
        <EditModal
          editId={data?.id}
          editModal={editModal}
          setEditModal={setEditModal}
        />
      )}
      {chatModal && (
        <ChatModal
          chatModal={chatModal}
          closeChatModal={closeChatModal}
          senderId={handleId}
          receiverId={data?.id}
        />
      )}
      {/* {chatModal && (
        <Chat
          isOpen={chatModal}
          closeChatModal={closeChatModal}
          fromChatUser={handleId}
          toChatUser={data?.id}
        />
      )} */}
      <div className={Styles.matchContainer}>
        <div className={Styles.headerComponent}>
          <div className={Styles.mathListName}>Trader - {data?.name}</div>
          <div className={Styles.statusSection}>
            <StatusBar />
          </div>
        </div>
        <div className={Styles.matchDetailsContainer}>
          <div className={Styles.matchInnerComponent}>
            <div className={Styles.elementLayout}>
              <div className={Styles.detailsHeading}>
                <PlaceIcon
                  style={{ height: "18px", width: "18px", color: "#F1935C" }}
                />
                <span>Region</span>
              </div>
              <div className={Styles.detailValue}>{data?.company?.region}</div>
            </div>
            <div className={Styles.elementLayout}>
              <div className={Styles.detailsHeading}>
                <PlaceIcon
                  style={{ height: "18px", width: "18px", color: "#F1935C" }}
                />
                <div>State</div>
              </div>
              <div className={Styles.detailValue}>{data?.company?.state}</div>
            </div>
            <div className={Styles.elementLayout}>
              <div className={Styles.detailsHeading}>
                <BoltIcon
                  style={{ height: "18px", width: "18px", color: "#F1935C" }}
                />
                <span>Quantity (MW)</span>
              </div>
              <div className={Styles.detailValue}>
                {data?.purchaseEnergy || data?.sellEnergy} MW
              </div>
            </div>
          </div>
          <div className={Styles.matchInnerComponent}>
            <div className={Styles.elementLayout}>
              <div className={Styles.detailsHeading}>
                <AccessTimeIcon
                  style={{ height: "18px", width: "18px", color: "#F1935C" }}
                />
                <span>Capacity Start & End Date</span>
              </div>
              <div className={Styles.detailValue}>
                {data?.dateStartAt} - {data?.dateEndAt}
              </div>
            </div>
            <div className={Styles.elementLayout}>
              <div className={Styles.detailsHeading}>
                <AccessTimeIcon
                  style={{ height: "18px", width: "18px", color: "#F1935C" }}
                />
                <span>Capacity Start & End Time</span>
              </div>
              <div className={Styles.detailValue}>
                {data?.slotStartAt} - {data?.slotEndAt}
              </div>
            </div>
            <div className={Styles.elementLayout}>
              <div className={Styles.detailsHeading}>
                <BoltIcon
                  style={{ height: "18px", width: "18px", color: "#F1935C" }}
                />
                <span>Energy Rate</span>
              </div>
              <div className={Styles.detailValue}>
                ₹{data?.sellingCost || data?.landingCost}/unit
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.buttonSection}>
          <div onClick={() => handleEdit()}>Edit</div>
          <div onClick={() => setChatModal(true)}>Chat</div>
          <div className={Styles.withdrawText}>Withdraw</div>
          <div className={Styles.agreementbtn} onClick={() => handleProceed()}>
            {status === "t1" && "Finalise Terms and Conditions"}
            {status === "t2" && "Upload Consent"}
            {status === "t3" && "Upload Agreement"}
            {status === "t4" && "View"}
            {status === "t5" && "View"}
          </div>
        </div>
      </div>
    </>
  );
};

export default ListingCard;
