import { getApi, postApi, postApi2 } from "./apiPromise";

export const getTraderData = ({ token, type }) => {
  let url = `${process.env.REACT_APP_API_URL}/${type}/capacity/getAllForTrader`;
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return postApi(url, headers);
};

export const getTraderConnectedData = ({ token, type, payload }) => {
  let url = `${process.env.REACT_APP_API_URL}/${type}/capacity/getAllForTrader`;
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  let body = payload;

  return postApi2(url, body, headers);
};

export const getBidDetails = ({ token, type, capId }) => {
  let url = `${process.env.REACT_APP_API_URL}/bidding/${type}/received?capacityId=${capId}&isHistory=false`;
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return getApi(url, headers);
};

export const getCapacityBidDetails = ({ token, type, capId, bidId }) => {
  let url = `${process.env.REACT_APP_API_URL}/bidding/${type}/${type}-capacity-bid-details?${type}CapacityId=${capId}&bidId=${bidId}`;
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return getApi(url, headers);
};

export const getConGenDashboardData = ({ token, type }) => {
  let url = `${process.env.REACT_APP_API_URL}/${type}/capacity/getAll?current=true`;
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return postApi(url, headers);
};

export const getConGenConnData = ({ token, type, capId }) => {
  let url = `${process.env.REACT_APP_API_URL}/capacity/landing-cost/matchmaking/${type}?capacityId=${capId}`;
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return postApi(url, headers);
};
