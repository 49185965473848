import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Styles from "../../Styles/Dashboard/TradeList.module.css";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BoltIcon from "@mui/icons-material/Bolt";
import { useDispatch, useSelector } from "react-redux";
import {
  bidDetails,
  getTraderConnectedList,
} from "../../Redux/Slices/traderSlice";
import { loadingFalse, loadingTrue } from "../../Redux/Slices/loadingSlice";

import { ReactComponent as Connected } from "../CommonComponents/svg/Connected.svg";
import { ReactComponent as Received } from "../CommonComponents/svg/Received.svg";
import { ReactComponent as Sent } from "../CommonComponents/svg/Sent.svg";

import ListingCard from "./ListingCard";
import { eventStatus } from "../../Redux/Slices/otherSlice";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  width: "95%",
  border: "1px solid #D4D4D4",
  borderRadius: "8px",
  background: "#FAFAFA",
  "&:not(:last-child)": {},
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor: "#fbfbfb",
    borderRadius: "8px",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  background: "#fbfbfb",
  borderRadius: "0px 0px 8px 8px",
  padding: "0px",
}));

const TradeList = ({ data }) => {
  const dispatch = useDispatch();
  const type = useSelector((state) => state?.otherSlice?.traderType);
  const token = useSelector((state) => state?.persistedReducer?.token);
  const status = useSelector((state) => state?.otherSlice?.eventStatus);
  const name = useSelector((state) => state?.persistedReducer?.user);
  const connectedList = useSelector(
    (state) => state?.traderSlice?.connectedList
  );
  const [expanded, setExpanded] = useState("");
  const [availableList, setAvailableList] = useState([]);
  const [bidId, setBidId] = useState([]);

  useEffect(() => {
    dispatch(eventStatus("t1"));
  }, []);

  useEffect(() => {
    if (status === "t4") {
      setTimeout(() => {
        dispatch(eventStatus("t5"));
      }, 3000);
    }
  }, [status]);

  const handleChange = (id) => {
    setExpanded(id);
    if (expanded !== id) {
      dispatch(loadingTrue());
      handleFetchData("PLACED", id);
    }
  };

  const handleFetchData = (value, id) => {
    dispatch(
      bidDetails({
        token: token,
        type: type,
        capId: id,
      })
    )
      .unwrap()
      .then((res) => {
        if (res?.user?.data?.code === 200) {
          setBidId(res?.user?.data?.data);
        }
      })
      .catch(() => {});
    let userType;

    if (type === "generator") {
      userType = "consumer";
    }
    if (type === "consumer") {
      userType = "generator";
    }

    let data = {
      search: [
        {
          operation: "LIKE",
          key:
            type === "generator"
              ? "bids_capacityGenerator_id"
              : "bids_capacityConsumer_id",
          value: id,
          value1: null,
        },
        value === "PLACED" && {
          operation: "LIKE",
          key:
            type === "generator"
              ? "bids_generatorStatus"
              : "bids_consumerStatus",
          value: value,
          value1: null,
        },
        value === "ACCEPTED" && {
          operation: "LIKE",
          key:
            type === "generator"
              ? "ownBids_generatorStatus"
              : "ownBids_consumerStatus",
          value: value,
          value1: null,
        },
        value === "RECEIVED" && {
          operation: "LIKE",
          key:
            type === "generator"
              ? "ownBids_generatorStatus"
              : "ownBids_consumerStatus",
          value: value,
          value1: null,
        },
      ].filter(Boolean),
    };

    dispatch(
      getTraderConnectedList({
        token: token,
        type: userType,
        payload: data,
      })
    )
      .unwrap()
      .then((res) => {
        if (res?.user?.data?.code === 200) {
          // setAvailableList(res?.user?.data?.data);
          dispatch(loadingFalse());
        }
      })
      .catch(() => {
        dispatch(loadingFalse());
        console.debug("error-login");
      });
  };

  return (
    <div className={Styles.listingMainContainer}>
      {data?.map((ele) => (
        <Accordion
          expanded={expanded === ele?.id}
          onChange={() => {
            handleChange(ele?.id);
          }}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className={Styles.traderListContainer}>
              <div className={Styles.elementLayout}>
                <div className={Styles.listHeading}>
                  {ele?.company?.companyName} - {ele?.name}
                </div>
                {status === "t1" && (
                  <div className={Styles.closeingDetails}>
                    Closed On : {ele?.endAt || ele?.bidEndAt}
                  </div>
                )}
              </div>
              {status === "t1" ? (
                <div className={Styles.detailsSection}>
                  <div className={Styles.elementLayout}>
                    <div className={Styles.detailsHeading}>
                      <DateRangeIcon
                        style={{
                          color: "#F1935C",
                          height: "16px",
                          width: "16px",
                        }}
                      />
                      <span>Date</span>
                    </div>
                    <div className={Styles.detailValue}>{ele?.dateEndAt}</div>
                  </div>
                  <div className={Styles.elementLayout}>
                    <div className={Styles.detailsHeading}>
                      <AccessTimeIcon
                        style={{
                          color: "#F1935C",
                          height: "16px",
                          width: "16px",
                        }}
                      />
                      <span>Time Slot</span>
                    </div>
                    <div className={Styles.detailValue}>
                      {ele?.slotStartAt} - {ele?.slotEndAt}
                    </div>
                  </div>
                  <div className={Styles.elementLayout}>
                    <div className={Styles.detailsHeading}>Quantity (MW)</div>
                    <div className={Styles.detailValue}>
                      {ele?.purchaseEnergy || ele?.sellEnergy} MW
                    </div>
                  </div>
                  <div className={Styles.elementLayout}>
                    <div className={Styles.detailsHeading}>
                      <BoltIcon
                        style={{
                          color: "#F1935C",
                          height: "16px",
                          width: "16px",
                        }}
                      />
                      <span>Energy Rate</span>
                    </div>
                    <div className={Styles.detailValue}>
                      ₹{ele?.sellingCost || ele?.landingCost}/unit
                    </div>
                  </div>
                </div>
              ) : (
                <div className={Styles.companyName}>
                  {name?.firstName} {name?.lastName}
                </div>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={Styles.matchListingContainer}>
              {/* <div className={Styles.matchFilterContainer}>
                <div className={Styles.eachFilterElemet}>
                  <Connected />
                  <div>Connected</div>
                </div>
                <div className={Styles.eachFilterElemet}>
                  <Received />
                  <div>Received</div>
                </div>
                <div className={Styles.eachFilterElemet}>
                  <Sent />
                  <div>Sent</div>
                </div>
              </div> */}
              <div className={Styles.matchLevelUp}>
                {/* {(availableList?.length > 0 || connectedList?.length > 0) &&
                  (connectedList?.length > 0
                    ? connectedList
                    : availableList
                  )?.map((elem) => (
                    <ListingCard data={elem} bidId={bidId} handleId={ele?.id} />
                  ))} */}
                {connectedList?.length > 0 &&
                  connectedList?.map((elem) => (
                    <ListingCard data={elem} bidId={bidId} handleId={ele?.id} />
                  ))}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default TradeList;
